import Gridbox from "./Gridbox";

const data = {
    content: {
       gridbox: [
           {
               title: "MOBILE FIRST DESIGN",
               text: "We start building your site for mobile devices first making sure the code is as lean and optimized with no bloated waste and makes the site even more responsive.",
               link: "More about web design",        
               url:  "/web-design"
           },
           {
               title: "FULLY RESPONSIVE",
               text: "Your website will fit all mobile screens sizes, tablets, and desktop sizes so visitors can access your site from anywhere and see a beautiful site no matter the screen size.",
               link: "More about responsiveness"         ,        
               url:  "/web-design"
           },
           {
               title: "OPTIMISED PAGE SPEED",
               text: "If your site takes more than 3 seconds to load you can lose up to 50% your traffic because it took too long. Our sites load 1 second or less ensuring everyone that visits your site reaches it.",
               link: "More about page speed"      ,        
               url:  "/web-design"   
           },
           {
               title: "SEO SERVICES",
               text: "We have an in-house SEO specialist who understands local SEO and how to rank in your local market with plenty of case studies to show results and monthly reports.",
               link: "More about SEO"     ,        
               url:  "/seo"    
           },
           {
               title: "GOOGLE PPC ADS",
               text: "We also offer Pay-Per-Click Google ads creation and management with our Google Ads expert who can create effective ad campaigns to maximize your ROI.",
               link: "More about PPC ads"   ,        
               url:  "/google-ppc-ads"      
           },
           {
               title: "BASED IN THE UK",
               text: "We do not hire cheap overseas developers. We are entirely based in the UK with team members in Sheffield, Manchester and Leeds and work 100% remote from home.",
               link: "More about us"  ,        
               url:  "/about-us"       
           }
       ],
    }
}   

export default function InfoGrid() {
    return (
        <div className="flex flex-row flex-wrap justify-center w-full max-w-[1300px]">
            {data.content.gridbox.map((i) => <Gridbox content={i}/>)}
        </div>
    );
}