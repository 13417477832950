export default function Landing() {

    const landingContent = {
        title: "CUSTOM DESIGNS. CUSTOM CODED.",
        subtitle: "SMALL BUSINESS WEB DESIGNER",
        text: "No page builders or WordPress. We offer 100% hand-coded websites with superior results starting at £400 as well as Google Ads and SEO services."
    }

    return (
        <div className="flex flex-col lg:flex-row items-center justify-center pt-[20vh] text-left w-full max-w-[1200px]">
            <div className="w-full lg:w-2/5 px-4">
                <h1 className="text-white text-base font-Orbitron font-bold">{landingContent.title}</h1>
                <h1 className="text-white text-4xl font-Orbitron font-black py-2">{landingContent.subtitle}</h1>
                <p className="text-white w-full lg:text-xl font-Oswald">{landingContent.text}</p>
                <div className="h-12 my-6">
                    <a href="/contact" className="px-8 py-4 h-full text-center font-Orbitron font-bold bg-lime-500 hover:bg-white hover:text-lime-500 duration-300 text-white rounded-full">GET STARTED</a>
                    <a href="/about-us" className="px-8 py-4 h-full text-center font-Orbitron font-base text-white ">ABOUT US</a>
                </div>
            </div>
            <img src="./mockup.png" className="lg:w-3/5 w-full p-4"/>
        </div>
    )
}