import Footer from "../Footer";
import Navbar from "../Navbar";
import Wave from "../Wave";
import Pricing from "../homepage/Pricing";

export default function PricingPage(props) {
    return(
        <div className='overflow-hidden bg-stone-900 text-center'>
            <Navbar />
            <div className="bg-white flex flex-col items-center">
                <div className="bg-stone-900 pt-[30vh] flex flex-col items-center w-full">
                    <h1 className="text-white font-Orbitron font-bold text-6xl">PRICING</h1>
                </div>
                <Wave />
                <Pricing />
            </div>  
            <Footer />
        </div>
    )
}