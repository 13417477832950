export default function Footer() {
    return(
        <div className="bg-stone-900 flex flex-col lg:flex-row mx-[15vw] py-10 text-white relative pb-20">
            <div className="w-full lg:w-1/2 max-w-[1200px] flex flex-col">
                <img src="/salixalbawhite.png" className="w-64"/>
                <p className="py-8 w-full lg:w-1/2">
                    We believe small businesses deserve better. Just because you’re small, doesn’t mean your site needs to be. Let us make you something amazing.
                </p>
                <a href="/contact" className="w-60 cursor-pointer px-8 py-4 text-center font-Orbitron text-black font-bold bg-white hover:bg-white hover:text-lime-500 duration-300 rounded-full">GET STARTED</a>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col lg:flex-row p-4 text-left">
                <div className="w-full lg:w-2/3 flex flex-col">
                    <h1 className="font-Oswald text-2xl py-4 px-2">QUICK LINKS</h1>
                    <div className="flex lg:flex-row flex-col">
                        <div className="flex flex-col px-2">
                            <a href="/" className="py-1 hover:text-lime-500 cursor-pointer">Home</a>
                            <a href="/about" className="py-1 hover:text-lime-500 cursor-pointer">About</a>
                            <a href="/pricing" className="py-1 hover:text-lime-500 cursor-pointer">Pricing</a>
                            <a href="/blog" className="py-1 hover:text-lime-500 cursor-pointer">Blog</a>
                        </div>
                        <div className="flex flex-col px-2">
                            <a href="/web-design" className="py-1 hover:text-lime-500 cursor-pointer">Web Design</a>
                            <a href="/seo" className="py-1 hover:text-lime-500 cursor-pointer">SEO</a>
                            <a href="/google-ppc-ads" className="py-1 hover:text-lime-500 cursor-pointer">Google PPC Ads</a>
                        </div>
                        
                    </div>
                </div>
                <div className="w-full lg:w-1/3 flex flex-col px-2">
                    <h1 className="font-Oswald text-2xl py-4">CONTACT INFORMATION</h1>
                    <a className="py-1">+44 7832 690875</a>
                    <a className="py-1">enquiries@salixalbaweb.com</a>
                </div>
            </div>
            <div className="absolute z-20 bottom-0 w-full">
                <hr className="w-full h-[1px] bg-white"/>
                <div className="flex flex-row justify-between py-2">
                    <h1>
                    © Copyright 2024 Salix Alba
                    </h1>
                </div>
            </div>
        </div>
    )
}