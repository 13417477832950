import SmallGridbox from "./SmallGridbox";

const data = {
    content: {
       smallGridbox: [
           {
               title: "100% SECURE",
               text: "Our sites are just static HTML and CSS code, meaning there's literally nothing that can be hacked.",    
           },
           {
               title: "CUSTOM DESIGNED",
               text: "Our designs are made by an in-house design team, which allows us to make anything we want.",    
           },
           {
               title: "100 PAGESPEED SCORES",
               text: "Our sites have zero bloat, zero waste, and built with purpose so we get perfect 98-100/100 speed scores with Google.",    
           },
           {
               title: "MONEY BACK GUARANTEE",
               text: "If we can’t design something you like, you get your money back and the contract is cancelled. We stand by our work.",    
           },
           {
               title: "UNMATCHED SUPPORT",
               text: "Call or text us anytime, no phone trees or robots. When you call us you get me - the owner and developer",    
           },
           {
               title: "WE KNOW WEBSITES",
               text: "No snake oil, no tricks, no lies. We explain every step of the process very clearly, you won't ever be left in the dark.",    
           },
       ]
    }
}

export default function Profile() {
    return(
        <div className="flex md:flex-row flex-col w-full max-w-[1200px] p-4">
            <div className="w-full lg:w-2/5">
                <img src="./code1.jpg" className="h-[80%] object-cover rounded-xl bg-opacity-15 bg-red-500 rounded-sm"/>
            </div>
            <div className="w-full lg:w-3/5 h-fulltext-left p-10">
                <h1 className="text-stone-500 font-Orbitron font-black text-md">
                    WHAT WE OFFER
                </h1>
                <h1 className="w-full max-w-[700px] font-Oswald font-black text-3xl pb-4 pt-2">
                    WEBSITES STARTING AT £400
                </h1>
                <p>
                    We offer £400 for a standard 5 page small business website. If you need 
                    more than that then we have to do custom pricing based on the scope of work, 
                    number of additional pages, and time involved. Includes design, development,
                    edits (fees apply) and lifetime support.
                </p>
                <div className="flex flex-row flex-wrap justify-center w-full max-w-[1300px] py-8">
                    <SmallGridbox title={data.content.smallGridbox[0].title} text={data.content.smallGridbox[0].text} link={data.content.smallGridbox[0].link}/>
                    <SmallGridbox title={data.content.smallGridbox[1].title} text={data.content.smallGridbox[1].text} link={data.content.smallGridbox[1].link}/>
                    <SmallGridbox title={data.content.smallGridbox[2].title} text={data.content.smallGridbox[2].text} link={data.content.smallGridbox[2].link}/>
                    <SmallGridbox title={data.content.smallGridbox[3].title} text={data.content.smallGridbox[3].text} link={data.content.smallGridbox[3].link}/>
                    <SmallGridbox title={data.content.smallGridbox[4].title} text={data.content.smallGridbox[4].text} link={data.content.smallGridbox[4].link}/>
                    <SmallGridbox title={data.content.smallGridbox[5].title} text={data.content.smallGridbox[5].text} link={data.content.smallGridbox[5].link}/>
                </div>
                <a href="/contact" className="px-12 py-4 text-center font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 duration-300 text-white rounded-full">SCHEDULE A CALL</a>
            </div>
        </div>
    );
}