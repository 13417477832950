import Footer from "../Footer";
import Navbar from "../Navbar";
import FeaturedPosts from "./FeaturedPosts";
import PostPreview from "./PostPreview";
import Wave from "../Wave";

const posts = require("./posts.json");

function MapJSONContent(post) {
    return( <>
        <img src={post.thumbnail} className="bg-red-500 bg-opacity-25 h-[300px] w-full object-cover"/>
        <div className="flex flex-col p-8">
            <h1 className="text-4xl font-Oswald">{post.title}</h1>
            <div className="flex flex-row items-center">
                <img src={post.author.profilePicture} className="h-10 w-10 rounded-full bg-red-500 bg-opacity-25 m-2 ml-0"/>
                <h1>{post.author.name}</h1>
                <hr className="h-2 w-2 rounded-full bg-lime-500 m-2"/>
                <h1>{post.date}</h1>
            </div>
            { post.content.map((i) =>
            <div key={i.id}>
                <div className="flex flex-row items-center">
                    <hr className="h-10 w-1 mr-2 bg-lime-500"/>
                    <h1 className="font-Oswald text-xl font-bold py-4">{i.subtitle}</h1>
                </div>
                <p>{i.text}</p>
            </div>
            )}
        </div>
    </> )
}

export default function Blog(props) {

    let page_content;
    if (props.post) { page_content = MapJSONContent(props.post)}
    else { page_content = posts.map((i) => <PostPreview post={i} key={i.id}/> )}

    return(
        <div className='overflow-hidden bg-stone-900'>
            <Navbar />
            <div className="bg-white flex flex-col items-center">
                <div className="bg-stone-900 pt-[30vh] flex flex-col items-center w-full">
                    <h1 className="text-white font-Orbitron font-bold text-5xl">BLOG</h1>
                </div>
                <Wave />
                <div className="w-full max-w-[1200px] flex flex-col-reverse items-center lg:items-start lg:flex-row p-4">
                    <div className="w-full lg:w-2/3 m-2">
                        {page_content}
                    </div>
                    <FeaturedPosts />
                </div>
            </div>  
            <Footer />
        </div>
    )
}