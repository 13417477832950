export default function Gridbox(props) {
    return(
        <div className="relative w-[400px] h-[400px] m-4">
            <div className="absolute w-20 h-20 rounded-2xl bg-lime-500 ml-4 z-10">

            </div>              
            <div className="absolute bottom-0 w-full h-[90%] bg-stone-100 rounded-lg py-16 px-8 hover:bg-stone-900 duration-300 hover:text-white">
                <h1 className="font-Oswald font-black text-2xl">
                    {props.content.title}
                </h1>
                <p className="py-4 text-lg">
                    {props.content.text}
                </p>
                <a href={props.content.url} className="font-Oswald underline cursor-pointer hover:text-lime-500 duration-100">
                    {props.content.link}
                </a>
            </div>
            
        </div>

    );
}