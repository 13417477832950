import Footer from "../Footer";
import Navbar from "../Navbar";
import Wave from "../Wave";

const content = require("./faq.json");

function DropdownBox(props) {
    return(
        <div className="w-full shadow-stone-500 shadow-md rounded-md my-4 p-6 flex flex-col justify-between">
            <h1 className="text-black font-Oswald text-2xl">{props.question}</h1>
            <p className="text-black pt-4">{props.answer}</p>
        </div>
    )
}

export default function FAQ(props) {
    return(
        <div className='overflow-hidden bg-stone-900'>
            <Navbar />
            <div className="bg-white flex flex-col items-center">
                <div className="bg-stone-900 pt-[30vh] flex flex-col items-center w-full">
                    <h1 className="text-white font-Orbitron font-bold text-6xl">FAQ</h1>
                </div>
                <Wave />
                <div className="flex flex-col w-full max-w-[1200px] py-8">
                    {content.pricing.map((entry) => <DropdownBox question={entry[0]} answer={entry[1]}/>)}
                </div>
            </div>  
            <Footer />
        </div>
    )
}