export default function Pricing() {
    return(
        <div className="w-full max-w-[1200px] py-8 flex flex-col items-center">
            <h1 className="text-stone-500 font-Orbitron font-black text-2xl px-4">
                OUR PRICING 
            </h1>
            <h1 className="w-full max-w-[700px] font-Oswald font-black text-6xl pt-4 pb-8 px-4">
                PRICING PLANS FOR EVERY BUDGET
            </h1>
            <div className="flex flex-wrap justify-center">
                <div className="flex flex-col bg-stone-100 hover:bg-stone-900 hover:text-white duration-200 rounded-lg p-8 w-[350px] justify-between m-2">
                    <h1 className="w-full max-w-[700px] font-Oswald font-black text-3xl pb-4 pt-2">
                        LUMP SUM
                    </h1>
                    <div className="h-full">
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Design and Development
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> £25/mo Hosting
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> £50 fee Per Page after 5
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Edits starting at £100
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> +£250 To Add A Blog
                        </p>
                    </div>
                    <div className="py-4">
                        <h1 className="w-full max-w-[700px] font-Orbitron font-black text-4xl pb-4 pt-2 text-lime-500 inline">
                            £400  
                        </h1>
                        <h1 className="w-full max-w-[700px] font-Orbitron font-black text-xl pb-4 pt-2 text-stone-500 inline">
                            {" "} STARTING
                        </h1>
                    </div>
                    <a href="/contact" className="py-2 px-8 text-center font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 duration-300 text-white rounded-full">GET STARTED</a>
                </div>
                <div className="flex flex-col bg-stone-100 hover:bg-stone-900 hover:text-white duration-200 rounded-lg p-8 w-[350px] justify-between m-2">
                    <h1 className="w-full max-w-[700px] font-Oswald font-black text-3xl pb-4 pt-2">
                        MONTHLY
                    </h1>
                    <div className="h-full">
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Design and Development
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Hosting Included
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> £100 one-off fee Per Page after 5
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Unlimited Edits Included
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> +£250 (one-off) To Add A Blog
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> 24/7 Support
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Lifetime Updates
                        </p>
                    </div>
                    <div className="py-4">
                        <h1 className="w-full max-w-[700px] font-Orbitron font-black text-4xl pb-4 pt-2 text-lime-500 inline">
                            £150  
                        </h1>
                        <h1 className="w-full max-w-[700px] font-Orbitron font-black text-xl pb-4 pt-2 text-stone-500 inline">
                            {" "} / MONTH
                        </h1>
                    </div>
                    <a href="/contact" className="py-2 px-8 text-center font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 duration-300 text-white rounded-full">GET STARTED</a>
                </div>
                <div className="flex flex-col bg-stone-100 hover:bg-stone-900 hover:text-white duration-200 rounded-lg p-8 w-[350px] justify-between m-2">
                    <h1 className="w-full max-w-[700px] font-Oswald font-black text-3xl pb-4 pt-2">
                        E-COMMERCE
                    </h1>
                    <div className="h-full">
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Design and Development
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Hosting Included
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> £100 one-off fee Per Page after 5
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Unlimited Edits Included
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> +£250 (one-off) To Add A Blog
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> 24/7 Support
                        </p>
                        <p className="flex flex-row items-center">
                            <img src="/check-mark.svg" className="h-6 inline m-2"/> Lifetime Updates
                        </p>
                    </div>
                    <div className="py-4">
                        <h1 className="w-full max-w-[700px] font-Orbitron font-black text-xl pb-4 pt-2 text-stone-500 inline">
                            UNAVAILABLE
                        </h1>
                    </div>
                    
                    <a href="/contact" className="py-2 px-8 text-center font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 duration-300 text-white rounded-full">GET STARTED</a>
                </div>
            </div>
        </div>
    );
}