export default function SmallGridbox(props) {
    return(
        <div className="relative w-[300px] h-[150px]">
            <div className="flex flex-row items-center">
                <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" className="w-8 inline">
                        <polygon fill="#84cc16" points="40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9"/>
                </svg>
                <h1 className="font-Oswald text-stone-500 text-lg inline pl-2">
                    {props.title}
                </h1>
            </div>
            
            <p className="pl-10">
                {props.text}
            </p>
        </div>

    );
}