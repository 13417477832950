export default function Portfolio() {
    return(
        <div className="bg-stone-100 flex flex-col w-full items-center py-12">
            <div className="w-full max-w-[1000px] flex flex-col lg:flex-row items-center">
                <div className="flex flex-col lg:w-1/2 lg:text-left text-center">
                    <h1 className="text-stone-500 font-Orbitron font-black text-xl">
                        OUR PORTFOLIO
                    </h1>
                    <h1 className="w-full max-w-[700px] font-Oswald font-black text-5xl pb-4 pt-2">
                        SOME OF THE WORK WE'VE DONE OVER THE YEARS
                    </h1>
                </div>
                <div className="h-[2px] w-40 lg:h-40 lg:w-[2px] my-4 bg-stone-900"/>
                <p className="p-4 lg:w-1/2 lg:text-left text-center">
                    We have worked with clients all over the US and Canada for home services contractors, 
                    restaurants, consulting agencies, start ups, dermatologists, doctors, accountants, 
                    and more. No matter what your business is, we can build a website that is effective, 
                    beautiful, performant, and tailored to your industry.
                </p>
            </div>
            <div className="w-full max-w-[1000px] flex flex-wrap justify-center">
                <div className="flex flex-col w-[300px] m-2">
                    <img src="./wgoodwin.png" className="h-[180px] w-full bg-red-500 bg-opacity-20"/>
                    <h1 className="w-full max-w-[700px] font-Oswald font-black text-xl pt-2">
                        W GOODWIN WEB
                    </h1>
                    <p className="py-4">
                        Created for a website development agency. Dark theming with plenty of motion.
                    </p>
                    <a href="https://wgoodwin.co.uk" target="_blank" className="w-40 h-12 flex justify-center items-center font-Orbitron font-bold bg-lime-500 hover:bg-white hover:text-lime-500 duration-300 text-white rounded-full">VISIT</a>
                </div>
                <div className="flex flex-col w-[300px] m-2">
                    <img src="./dominionofashes.png" className="h-[180px] w-full bg-red-500 bg-opacity-20"/>
                    <h1 className="w-full max-w-[700px] font-Oswald font-black text-xl pt-2">
                        DOMINION OF ASHES
                    </h1>
                    <p className="py-4">
                        Sheffield-based technical death metal band. Static site with EPK, merch links and calendar.
                    </p>
                    <a href="https://dominionofashes.com" target="_blank" className="w-40 h-12 flex justify-center items-center font-Orbitron font-bold bg-lime-500 hover:bg-white hover:text-lime-500 duration-300 text-white rounded-full">VISIT</a>
                </div>
                <div className="flex flex-col w-[300px] m-2">
                    <img src="./aracodingservices.png" className="h-[180px] w-full bg-red-500 bg-opacity-20"/>
                    <h1 className="w-full max-w-[700px] font-Oswald font-black text-xl pt-2">
                        ARA CODING SERVICES
                    </h1>
                    <p className="py-4">
                        Created for a software engineering and web development agency. Simple, but effective.
                    </p>
                    <a href="https://willxw.com" target="_blank" className="w-40 h-12 flex justify-center items-center font-Orbitron font-bold bg-lime-500 hover:bg-white hover:text-lime-500 duration-300 text-white rounded-full">VISIT</a>
                </div>
            </div>
        </div>
    );
}