import Footer from "../Footer";
import Navbar from "../Navbar";
import Wave from "../Wave";

export default function ContactPage(props) {
    return(
        <div className='overflow-hidden bg-stone-900 w-screen'>
            <Navbar />
            <div className="bg-white flex flex-col items-center">
                <div className="bg-stone-900 pt-[20vh] lg:pt-[30vh] flex flex-col items-center text-center w-full">
                    <h1 className="text-white font-Orbitron font-bold text-6xl">CONTACT US</h1>
                </div>
                <Wave />
                <div className="flex flex-col lg:flex-row w-full max-w-[1200px] pb-20">
                    <div className="w-full lg:w-1/2 p-8">
                        <h1 className="text-stone-500 text-base font-Orbitron font-bold">CONTACT US</h1>
                        <h1 className="text-black text-4xl font-Orbitron font-black py-2">GET IN TOUCH</h1>
                        <p className="text-black w-full lg:text-lg">Send us an email and let us know everything you want out of a new site. We respond to everyone within 24 hours of contacting us. Or give us a call and reach us immediately for a chat. If we are unavailable we will call back same day.</p>
                        <div className="flex flex-row items-center py-2">
                            <img src="/phone-76.svg" className="h-10 m-4"/>
                            <div className="flex flex-col">
                                <h1 className="text-xl font-Oswald">PHONE</h1>
                                <h1 className="text-xl text-stone-500">+44 7832 690875</h1>
                            </div>
                        </div>
                        <div className="flex flex-row items-center py-2">
                            <img src="/mail-76.svg" className="h-10 m-4"/>
                            <div className="flex flex-col">
                                <h1 className="text-xl font-Oswald">E-MAIL</h1>
                                <h1 className="text-xl text-stone-500">enquiries@salixalbaweb.com</h1>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 p-8 shadow-stone-500">
                        <img src="/code1.jpg" className="object-cover h-full w-full rounded-md"/>
                    </div>
                </div>
            </div>  
            <Footer />
        </div>
    )
}