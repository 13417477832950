import Landing from "./Landing";
import Wave from "../Wave";
import Portfolio from "./Portfolio";
import Profile from "./Profile";
import InfoGrid from "./InfoGrid";
import Performance from "./Performance";
import Pricing from "./Pricing";
import Navbar from "../Navbar";
import Footer from "../Footer";

export default function Homepage() {
    return (
        <div className='overflow-hidden bg-stone-900 -z-10'>
            <Navbar />
            <div className=" z-10 relative flex flex-col items-center justify-center">
                <Landing />
                <div className="bg-white flex flex-col items-center w-screen">
                    <Wave />
                    <h1 className="text-stone-500 font-Orbitron font-black text-xl">
                        WHAT WE DO
                    </h1>
                    <h1 className="w-full max-w-[700px] font-Oswald font-black text-6xl text-center pb-4 pt-2">
                        NEVER WORRY ABOUT YOUR WEBSITE EVER AGAIN
                    </h1>
                    <p className="w-[90%] max-w-[1000px] text-center">
                        At Salix Alba, we specialize in small business web design and development 
                        for clients anywhere in the United Kingdom. Every line of code is written by hand to 
                        ensure the best performance and make Google happy, which helps bring in more customers 
                        to your site and bring more revenue to your business. We also manage the edits for you 
                        and will never leave you high and dry. Our goal is to create long term relationships
                        with our clients and see them grow over time.
                    </p>
                    <InfoGrid />
                    <a href="/contact" className="w-64 h-16 my-16 font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 duration-300 text-white rounded-full flex items-center justify-center">GET STARTED</a>
                    <Profile />
                    <Portfolio />
                    <Performance />
                    <Pricing />
                    <div className="w-full bg-white flex flex-col">
                        <div className="rotate-180">
                            <Wave />
                        </div>
                        <h1 className=" pt-20 text-white font-Oswald font-black text-6xl text-center bg-stone-900 -m-[1px]">
                            READY TO GET THE SITE YOU ALWAYS WANTED?
                        </h1>
                        <div className="bg-stone-900 flex flex-col items-center">
                            <a href="/contact" className="my-10 py-4 px-12 font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 duration-300 text-white rounded-full">GET STARTED</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
  
    )
}