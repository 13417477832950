export default function Performance() {
    return(
        <div className="w-full bg-lime-950 flex flex-col items-center py-10">
            <div className="w-full max-w-[1200px] flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2 text-center lg:text-left p-4">
                    <h1 className="text-white font-Orbitron font-black text-xl">
                        PERFORMANCE
                    </h1>
                    <div className="py-4">
                        <h1 className="font-Oswald font-black text-white text-6xl text-center inline">
                            WE BUILD
                        </h1>
                        <h1 className="font-Oswald font-black text-lime-500 text-6xl text-center inline">
                            {" "}BETTER{" "}
                        </h1>
                        <h1 className="font-Oswald font-black text-white text-6xl text-center inline">
                            WEBSITES THAT PERFORM
                        </h1>
                    </div>
                    <p className="text-white">
                        When it comes to website load times, not very many can get the Google PageSpeed 
                        scores that we get with each and every site. Test your website load times with 
                        Google PageSpeed Insights and see what your current site is scoring right now.
                    </p>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center">
                            <img src="./check-mark.svg" className="h-16 w-16 m-2 p-2"/>
                            <p className="text-white">
                                Better load times means more traffic and more site conversions over time.
                            </p>
                        </div>
                        <div className="flex flex-ro items-center">
                            <img src="./check-mark.svg" className="h-16 w-16 m-2 p-2"/>
                            <p className="text-white">
                                Faster websites can help improve SEO and your Google ads performance.
                            </p>
                        </div>
                        <div className="flex flex-row items-center">
                            <img src="./check-mark.svg" className="h-16 w-16 m-2 p-2"/>
                            <p className="text-white">
                                Our sites load instantly in under 1 second or less, which leads to a better user experience and conversions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/2 text-left flex flex-col p-4 justify-between">
                    <div className="flex flex-row justify-between w-full">
                        <div className="flex flex-col  w-1/5">
                            <h1 className="font-Oswald font-black text-lime-500 text-4xl lg:text-6xl inline">
                                100%
                            </h1>
                            <h1 className="font-Oswald text-white text-md lg:text-2xl inline">
                                Satisfaction Guaranteed
                            </h1>
                        </div>
                        <div className="flex flex-col  w-1/5">
                            <h1 className="font-Oswald font-black text-lime-500 text-4xl lg:text-6xl inline">
                                100
                            </h1>
                            <h1 className="font-Oswald text-white text-md lg:text-2xl inline">
                                Page Speed Scores
                            </h1>
                        </div>
                        <div className="flex flex-col w-1/5">
                            <h1 className="font-Oswald font-black text-lime-500 text-4xl lg:text-6xl inline">
                                5/5
                            </h1>
                            <h1 className="font-Oswald text-white text-md lg:text-2xl inline">
                                Google Reviews
                            </h1>
                        </div>
                    </div>
                    <img src="./pagespeed.png" className="h-full w-full mt-4 rounded-xl"/>
                </div>
            </div>
        </div>
    );
}