export default function PostPreview(props) {

    let post = props.post
    let link = "/blog/" + post.url

    return(
        <div className="flex flex-col border-[1px] rounded-md mb-4">
            <img src={post.thumbnail} className="bg-red-500 bg-opacity-25 h-[300px] w-full object-cover"/>
            <div className="flex flex-col p-8">
                <div className="flex flex-row items-center">
                    <img src={post.author.profilePicture} className="h-10 w-10 rounded-full bg-red-500 bg-opacity-25 m-2 ml-0 object-cover"/>
                    <h1>{post.author.name}</h1>
                    <hr className="h-2 w-2 rounded-full bg-lime-500 m-2"/>
                    <h1>Nov 8, 2024</h1>
                </div>
                <a href={link} className="text-2xl font-Oswald cursor-pointer hover:text-lime-500 duration-300">{post.title}</a>
                <p className="py-4">
                    {post.synopsis}
                </p>
            </div>
        </div>
    )
}