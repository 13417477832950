import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Blog from "./components/blog/Blog";
import Homepage from "./components/homepage/Homepage"
import ContentPage from "./components/contentPage/ContentPage";
import PricingPage from "./components/pricing/PricingPage";
import FAQ from "./components/faq/FAQ";
import ContactPage from "./components/contact/ContactPage";


// import json
const blogPosts = require("./components/blog/posts.json");
const navLinks = require("./components/navLinks.json");

const router = createBrowserRouter(navLinks.map((link) => { 
    switch (link.type) {
      case "image":
        return ({ path: link.url, element: <Homepage /> })
      case "blog":
        return ({ path: link.url, element: <Blog /> })
      case "pricingPage":
        return ({ path: link.url, element: <PricingPage /> })
      case "contactPage":
        return ({ path: link.url, element: <ContactPage /> })
      case "faq":
        return ({ path: link.url, element: <FAQ /> })
      case "dropdownParent":
        return link.children.map((child) => ({ path: child.url, element: <ContentPage title={child.title}/> }))
      default:
        return ({ path: link.url, element: <ContentPage title={link.title}/> })
    }
}).concat(blogPosts.map((i) => ({ 
  path: "/blog/"+i.url, element: <Blog type="post" post={i}/> 
}))).flat())


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);