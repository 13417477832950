import Footer from "../Footer";
import Navbar from "../Navbar";
import Wave from "../Wave";
const content = require("./content.json");


export default function ContentPage(props) {

    let pageContent
    content.map((page) => {if (page.title == props.title) pageContent = page})
    

    return(
        <div className='overflow-hidden bg-stone-900'>
            <Navbar />
            <div className="bg-white flex flex-col items-center">
                <div className="bg-stone-900 pt-[20vh] lg:pt-[30vh] pb-[10vh] lg:pb-0 flex flex-col items-center w-full">
                    <h1 className="text-white font-Orbitron font-bold text-4xl text-center">{pageContent.title}</h1>
                </div>
                <Wave />
                <div className="bg-white flex flex-col lg:flex-row justify-between w-full max-w-[1200px] pb-12">
                    <div className="w-full p-6 relative flex flex-row">
                        <img src={pageContent.images[0]} className="h-1/2 w-1/2 absolute right-6 lg:right-0 top-1/4 rounded-xl object-cover"/>
                        <img src={pageContent.images[1]} className="h-full w-full lg:w-full rounded-xl min-h-[400px] object-cover"/>
                    </div>
                    <div className="w-full flex flex-col text-left p-6">
                        <h1 className="text-stone-500 font-Orbitron font-black text-xl">
                            {pageContent.text[0].caption}
                        </h1>
                        <h1 className="font-Oswald font-black text-6xl pb-4 pt-2">
                            {pageContent.text[0].subtitle}
                        </h1>
                        <p className="">{pageContent.text[0].paragraph}</p>
                        <a href="/contact "className="py-4 px-8 my-4 text-center font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 duration-300 text-white rounded-full">GET STARTED</a>
                    </div>
                </div>
                <div className="bg-stone-900 flex flex-col w-full items-center py-12">
                    <div className="w-full max-w-[1000px] flex flex-col lg:flex-row items-center">
                        <div className="flex flex-col lg:w-1/2 lg:text-left text-center">
                            <h1 className="text-lime-500 font-Orbitron font-black text-xl">
                                OUR WORKING PROCESS
                            </h1>
                            <h1 className="text-white w-full max-w-[700px] font-Oswald font-black text-5xl pb-4 pt-2">
                                WE TAKE CARE OF EVERYTHING
                            </h1>
                        </div>
                        <div className="h-[2px] w-40 lg:h-40 lg:w-[2px] my-4 bg-stone-900"/>
                        <p className="p-4 lg:w-1/2 lg:text-left text-center text-white">
                            After a contract is signed we send a small questionnaire about your business 
                            and what you offer, then we expand on that content and write the entire site’s 
                            content for you, create the design, get approval, then we build. Generally takes
                            2-3 weeks from start to finish.
                        </p>
                    </div>
                    <div className="w-full max-w-[1000px] flex flex-wrap justify-center">
                        <div className="flex flex-col w-[300px] m-2">
                            <img src="https://www.snapsurveys.com/blog/wp-content/uploads/2023/03/what-is-a-questionnaire-blog-image.webp" className="h-[180px] w-full bg-red-500 bg-opacity-20"/>
                            <h1 className="text-lime-500 font-Orbitron font-black text-md">
                                STEP 1
                            </h1>
                            <h1 className="w-full max-w-[700px] font-Oswald font-black text-2xl text-white">
                                COMPLETE QUESTIONNAIRE
                            </h1>
                            <p className=" text-white">
                                We send you an email with less than 10 questions about you business, services, what sets you apart, etc and we do all the rest.
                            </p>
                        </div>
                        <div className="flex flex-col w-[300px] m-2">
                            <img src="https://loghousecabins.co.uk/wp-content/uploads/2017/09/planningpermission.jpg" className="h-[180px] w-full bg-red-500 bg-opacity-20"/>
                            <h1 className="text-lime-500 font-Orbitron font-black text-md">
                                STEP 2
                            </h1>
                            <h1 className="w-full max-w-[700px] font-Oswald font-black text-2xl text-white">
                                DESIGN APPROVAL
                            </h1>
                            <p className=" text-white">
                                We then do a video screen share showing you the first draft and make any changes you want to make sure you're 100% satisfied.
                            </p>
                        </div>
                        <div className="flex flex-col w-[300px] m-2">
                            <img src="https://blog.byldgroup.com/wp-content/uploads/2020/01/Self-Development.jpg" className="h-[180px] w-full bg-red-500 bg-opacity-20"/>
                            <h1 className="text-lime-500 font-Orbitron font-black text-md">
                                STEP 3
                            </h1>
                            <h1 className="w-full max-w-[700px] font-Oswald font-black text-2xl text-white">
                                DEVELOPMENT
                            </h1>
                            <p className=" text-white">
                                Once approved, we can start coding the site out and setting everything up. We will send a demo link to view before we launch and go live.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-white flex flex-col lg:flex-row justify-between w-full max-w-[1200px] pb-12">
                    
                    <div className="w-full flex flex-col text-left p-6">
                    <h1 className="text-stone-500 font-Orbitron font-black text-xl">
                            {pageContent.text[1].caption}
                        </h1>
                        <h1 className="font-Oswald font-black text-6xl pb-4 pt-2">
                            {pageContent.text[1].subtitle}
                        </h1>
                        <p className="">{pageContent.text[1].paragraph}</p>
                        <button className="w-64 h-16 my-4 text-center font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 duration-300 text-white rounded-full">GET STARTED</button>
                    </div>
                    <div className="w-full p-6 relative flex flex-row-reverse">
                        <img src={pageContent.images[2]} className="h-1/2 w-1/2 absolute left-6 lg:left-0 top-1/4 rounded-xl object-cover"/>
                        <img src={pageContent.images[3]} className="min-h-[400px] h-full w-4/5 lg:w-full right-0 rounded-xl object-cover"/>
                    </div>
                </div>
            </div>  
            <Footer />
        </div>
    )
}