import { useEffect, useState, Component } from 'react';
import classNames from 'classnames';
const navLinks = require("./navLinks.json");

export default function Navbar() {

    const [scroll, setScroll] = useState(false)
    const [sideNavOpen, setSideNavOpen] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const navBarClass = classNames({ 'w-full h-[20vw] max-h-[100px] flex justify-between items-center fixed top-0 duration-500 z-20': true, 'px-6 top-6 lg:px-20 lg:top-8': !scroll, });
    const internalBarClass = classNames({'bg-white h-full w-full flex items-center justify-between p-2 px-4 shadow-md shadow-stone-500 z-60': true, 'rounded-sm': !scroll, });
    const bugerClass1 = classNames({ "absolute w-full h-[10%] bg-stone-900 duration-500 rounded-full top-0 left-0": true, 'rotate-45 top-[45%]' : sideNavOpen });
    const bugerClass2 = classNames({ "absolute w-full h-[10%] bg-stone-900 duration-500 rounded-full top-[45%] left-0": true, 'opacity-0 -left-[100%]' : sideNavOpen });
    const bugerClass3 = classNames({ "absolute w-full h-[10%] bg-stone-900 duration-500 rounded-full bottom-0 left-0": true, '-rotate-45 bottom-[45%] bg-red-500' : sideNavOpen});
    const sideNavClass = classNames({
        'w-screen lg:w-1/3 h-screen bg-stone-900 fixed flex flex-col items-center justify-center duration-500 lg:hidden z-[15]' : true,
        'right-0' : sideNavOpen,
        '-right-full' : !sideNavOpen
    });
    const sideNavLinkClass = "font-Orbitron text-white font-black text-3xl flex-col hover:text-lime-500 duration-300 cursor-pointer py-2 text-center"

    let navbarLinks = navLinks.map((link) => { switch (link.type) {
        case "image": return (
            <li key={link.id} className="font-Orbitron font-black text-md flex flex-col hover:text-lime-500 duration-300 cursor-pointer h-full">
                <a href={link.url} className='h-full'>
                    <img src={link.source} className="h-full aspect-video"/>
                    
                </a>
            </li> )
        case "dropdownParent": return (
            <li key={link.id} className="font-Orbitron font-black text-[1vw] flex-col hover:text-lime-500 duration-300 group hidden lg:flex">
                <a className='group-hover:block py-4'>{link.title}</a>
                <div className="group-hover:block dropdown-menu absolute hidden h-auto duration-300 bg-red-500">
                    <ul className="absolute top-[3vw] bg-white shadow-md shadow-stone-900 px-6 py-4 border-b-[10px] border-lime-500 ronded-md w-60">
                        { link.children.map((child) => 
                            <li key={child.id} className="block text-black hover:text-lime-500 font-bold text-[1vw] uppercase duration-100 cursor-pointer my-2">
                                <a href={child.url}>{child.title}</a>
                            </li> 
                        )}
                    </ul>
                </div>
            </li>)
            default: return (
                <li key={link.id} className="font-Orbitron font-black text-[1vw] flex-col hover:text-lime-500 duration-300 cursor-pointer hidden lg:flex">
                    <a href={link.url}>{link.title}</a>
                </li>)
    }})

    let sideNavLinks = navLinks.map((link) => { switch (link.type) {
        default: return (
            <li key={link.id} className={sideNavLinkClass}><a href={link.url}>{link.title}</a></li>
        )
        case "dropdownParent": return ( link.children.map((child) =>
            <li key={child.id} className={sideNavLinkClass}><a href={child.url}>{child.title}</a></li>
        ));
    }})

    return (
        <>
            <div className={navBarClass}>
                <ul className={internalBarClass}>
                    {navbarLinks}
                    <a href="/contact" className="px-8 cursor-pointer py-4 text-center font-Orbitron font-bold bg-lime-500 hover:bg-black hover:text-lime-500 text-[1vw] duration-300 text-white rounded-full hidden lg:block">
                        GET STARTED
                    </a>
                    <button className="h-10 w-12 relative lg:hidden" onClick={() => setSideNavOpen(!sideNavOpen)}>
                        <span className={bugerClass1}/>
                        <span className={bugerClass2}/>
                        <span className={bugerClass3}/>
                    </button>
                </ul>
            </div>
            <ul className={sideNavClass}>
                {sideNavLinks}
            </ul>
        </>

    )

}