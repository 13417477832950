export default function Wave(props) {

    let path;

    switch(props.size) {
        case ("small"):
            path = "M0,128L80,112C160,96,320,64,480,74.7C640,85,800,139,960,144C1120,149,1280,107,1360,85.3L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        default:
            path = "M0,128L60,133.3C120,139,240,149,360,165.3C480,181,600,203,720,213.3C840,224,960,224,1080,202.7C1200,181,1320,139,1380,117.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
    }

    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280" className="-m-[1px]">
            <path fill="#1c1917" fillOpacity="1" d={path}/>
        </svg>
    )
}