const posts = require("./posts.json");

function FeaturedPost(props) {

    let post = props.post
    let link = "/blog/" + post.url

    return(
        <div className="flex flex-row items-center my-2">
            <img src={post.thumbnail} className="w-16 h-16 m-2 object-cover"/>
            <div className="flex flex-col">
                <a href={link} className="font-Oswald text-md line-clamp-1">{post.title}</a>
                <h2 className="font-Oswald text-md text-stone-500">{post.author.name}</h2>
                <h3 className="font-Oswald text-sm text-stone-500">{post.date}</h3>
            </div>
        </div>
    )
}

export default function FeaturedPosts() {
    return(
        <div className="w-full lg:w-1/3 border-[1px] rounded-md p-10 flex flex-col items-center m-2">
            <h1 className="font-Orbitron text-2xl">FEATURED POSTS</h1>
            <hr className="h-1 bg-stone-900 w-2/3 my-6"/>
            <FeaturedPost post={posts[0]}/>
            <hr className="h-[1px] bg-stone-900 w-2/3 my-6"/>
            <FeaturedPost post={posts[1]}/>
            <hr className="h-[1px] bg-stone-900 w-2/3 my-6"/>
            <FeaturedPost post={posts[2]}/>
            <hr className="h-[1px] bg-stone-900 w-2/3 my-6"/>
        </div>
    )
}